import axios from "axios";

export const generateConfirmationReport = (visitor) => async () => {
  axios
    .post(
      `${process.env.REACT_APP_SERVICE_URL}/api/tickets/generateConfirmation`,
      visitor,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Quittung.pdf");
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};
