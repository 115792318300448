import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import NotExistParkingZone from "../common/assets/notExistParkingZone.png";
import ModalHeaderWithImage from "../common/modal/modalHeadereWithImage";
import { getModalStyle } from "../reusable/functions";
import Button from "../common/button/button";
import { useTranslation } from "react-i18next";

const ModalWrongDailyCode = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      center
      showCloseIcon={false}
      styles={getModalStyle()}
    >
      <ModalHeaderWithImage
        textLabel={t("UserHome.Wrong daily code")}
        backgroundSrc={NotExistParkingZone}
        xIconVisibleOnImage
        onClose={props.close}
      />
      <Button
        text={t("UserHome.Repeat Entry")}
        onClick={props.reEnterCode}
        buttonStyle={{ marginBottom: "20px", width: "80%" }}
      />
    </Modal>
  );
};
export default ModalWrongDailyCode;
