import CheckCircle from "../../common/assets/check-circle.svg";
import Style from "./headingWithWelcomeLabel.module.css";

const HeadingWithWelcomeLabel = (props) => {
  return (
    <div
      className={
        props.home ? Style.headingWithBeppoLogoHome : Style.headingWithBeppoLogo
      }
    >
      <div className={Style.welcomeLabelContainer}>
        <div className={Style.hairlineHeading}></div>
        <div className={Style.welcomeLabel}>{props.label}</div>
      </div>
    </div>
  );
};

export default HeadingWithWelcomeLabel;
