export function getSwissCantonImages(canton) {
  switch (canton) {
    case "AG":
      return "/images/ch/ag.png";
    case "AI":
      return "/images/ch/ai.png";
    case "AR":
      return "/images/ch/ar.png";
    case "BE":
      return "/images/ch/be.png";
    case "BL":
      return "/images/ch/bl.png";
    case "BS":
      return "/images/ch/bs.png";
    case "CH":
      return "/images/ch/ch.png";
    case "FR":
      return "/images/ch/fr.png";
    case "GE":
      return "/images/ch/ge.png";
    case "GL":
      return "/images/ch/gl.png";
    case "GR":
      return "/images/ch/gr.png";
    case "JU":
      return "/images/ch/ju.png";
    case "LU":
      return "/images/ch/lu.png";
    case "NE":
      return "/images/ch/ne.png";
    case "NW":
      return "/images/ch/nw.png";
    case "OW":
      return "/images/ch/ow.png";
    case "SH":
      return "/images/ch/sh.png";
    case "SO":
      return "/images/ch/so.png";
    case "SZ":
      return "/images/ch/sz.png";
    case "TG":
      return "/images/ch/tg.png";
    case "TI":
      return "/images/ch/ti.png";
    case "UR":
      return "/images/ch/ur.png";
    case "VD":
      return "/images/ch/vd.png";
    case "VS":
      return "/images/ch/vs.png";
    case "ZG":
      return "/images/ch/zg.png";
    case "ZH":
      return "/images/ch/zh.png";
    default:
      return null;
  }
}

export function getCountryImages(country) {
  switch (country) {
    case "CH":
      return "/images/ch/ch.png";
    default:
      return null;
  }
}

export function getModalStyle() {
  return {
    modal: { backgroundColor: "#f2f2f2", padding: 0 },
    closeIcon: { backgroundColor: "red", fill: "white" },
  };
}

export function getModalStyleWithoutRedBackground() {
  return {
    modal: { backgroundColor: "#f2f2f2", padding: 0 },
    closeIcon: { backgroundColor: "#f2f2f2", fill: "red" },
  };
}
