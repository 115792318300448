import {
  CHECK_VISITOR_STATUS,
  CLEAR_VISITOR_STATUS,
  PARKING_ZONE_NOT_EXIST,
  VISITOR_ALREADY_SIGNED_IN,
  VISITOR_WAITING_FOR_FREEZE_TIME_TO_EXPIRE,
} from "../actions/types";

const initialState = {
  visitorStatusInZone: null,
  parkingZoneStatus: true,
  visitorAlreadySignedIn: false,
  visitorWaitingForFreezeTimeToExpire: false,
};

const userHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_VISITOR_STATUS:
      return {
        ...state,
        visitorStatusInZone: action.payload.status,
      };
    case CLEAR_VISITOR_STATUS:
      return {
        ...state,
        visitorStatusInZone: null,
      };
    case PARKING_ZONE_NOT_EXIST:
      return {
        ...state,
        parkingZoneStatus: false,
      };
    case VISITOR_ALREADY_SIGNED_IN:
      return {
        ...state,
        visitorAlreadySignedIn: action.payload,
        visitorWaitingForFreezeTimeToExpire: false,
      };
    case VISITOR_WAITING_FOR_FREEZE_TIME_TO_EXPIRE:
      return {
        ...state,
        visitorAlreadySignedIn: false,
        visitorWaitingForFreezeTimeToExpire: action.payload,
      };
    default:
      return state;
  }
};

export default userHomeReducer;
