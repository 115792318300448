import Style from "./modalHeadereWithImage.module.css";
import XWhiteForBackground from "../../common/assets/close-icon-white.svg";

const ModalHeaderWithImage = (props) => {
  return (
    <div className={Style.headerWithImageContainer}>
      <img src={props.backgroundSrc} alt="notExistParkingZone" width={"100%"} />
      {props.xIconVisibleOnImage && (
        <div onClick={() => props.onClose()}>
          <img
            src={XWhiteForBackground}
            alt="hand-stop"
            width={"9%"}
            style={{ position: "absolute", right: 20, top: 20 }}
          />
        </div>
      )}

      <div className={Style.textContainer}>
        <div className={Style.hairlineHeading} />
        <div>{props.textLabel}</div>
      </div>
    </div>
  );
};

export default ModalHeaderWithImage;
