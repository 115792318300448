import { combineReducers } from "redux";
import userHome from "./userHome";
import visitor from "./visitor";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userHome,
  visitor,
});

export default persistReducer(persistConfig, rootReducer);
