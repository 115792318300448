import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Button from "../common/button/button";
import { Controller, useForm } from "react-hook-form";
import Group13 from "../common/assets/group13.svg";
import LabelWithHR from "../common/label/labelWithHR";
import ModalHeader from "../common/modal/modalHeader";
import {
  getModalStyle,
  getModalStyleWithoutRedBackground,
} from "../reusable/functions";
import Style from "./modalLicensePlate.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ModalLicensePlate = (props) => {
  const { handleSubmit, control } = useForm();
  const { t } = useTranslation();

  const [licensePlateInputValue, setLicensePlateInputValue] = useState("");

  const setLicensePlate = () => {
    props.setLicensePlate(licensePlateInputValue);
    props.close();
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      center
      styles={getModalStyleWithoutRedBackground()}
    >
      <ModalHeader textLabel={t("Global.enter")} src={Group13} />
      <form className={Style.form} onSubmit={handleSubmit(setLicensePlate)}>
        <LabelWithHR label={t("UserHome.License plate")} />
        <Controller
          name="licensePlateInputValue"
          control={control}
          render={() => (
            <div className={Style.licensePlateOuter}>
              <div className={Style.licensePlateMid}>
                <div className={Style.licensePlateInner}>
                  <input
                    type={"text"}
                    className={`${Style.inputMask} ${Style.inputMaskText}`}
                    value={licensePlateInputValue.toUpperCase()}
                    onChange={(e) => {
                      if (e.target.value.includes(" ")) {
                        return;
                      }
                      setLicensePlateInputValue(
                        e.target.value.trim().toUpperCase()
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        />

        <Button
          text={t("Global.continue")}
          buttonStyle={{ marginBottom: "20px" }}
          disabled={!licensePlateInputValue}
        />
      </form>
    </Modal>
  );
};
export default ModalLicensePlate;
