import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Controller, useForm } from "react-hook-form";
import ModalHeader from "../common/modal/modalHeader";
import {
  getModalStyle,
  getModalStyleWithoutRedBackground,
} from "../reusable/functions";
import Style from "./modalParkingZone.module.css";
import { useDispatch } from "react-redux";
import { checkValidParkingZone } from "../actions/parkingZone";
import { useTranslation } from "react-i18next";

const ModalParkingZone = (props) => {
  const { control } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const checkParkingZone = (parkingZone) => {
    dispatch(
      checkValidParkingZone(
        parkingZone,
        props.openNotExistParkingZoneModal,
        props.setParkingZone,
        closeModal
      )
    );
  };

  const closeModal = () => {
    props.close();
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      center
      styles={getModalStyleWithoutRedBackground()}
    >
      <ModalHeader textLabel={t("UserHome.Enter Parking Zone")} />
      <div className={Style.form}>
        <Controller
          name="parkingZone"
          control={control}
          render={() => (
            <input
              type="number"
              className={Style.inputMask}
              name="parkingZone"
              onChange={(e) => {
                if (e.target.value.length > 9) {
                  return;
                }
                if (e.target.value.length === 9) {
                  checkParkingZone(e.target.value);
                }
              }}
            />
          )}
        />
      </div>
    </Modal>
  );
};
export default ModalParkingZone;
