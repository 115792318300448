import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { persistor, store } from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { axiosConfig } from "./axios";
import UserHome from "./components/userHome";
import LegalVisitor from "./components/legalVisitor";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    axiosConfig();
  });
  return (
    <Provider store={store}>
      <Router>
        <PersistGate persistor={persistor}>
          <Switch>
            <Route exact path="/legalVisitor" component={LegalVisitor} />
            <Route
              exact
              path={[
                "/userHome/:parkingZone",
                "/userHome",
                "/userHome/:parkingZone/:code",
              ]}
              component={UserHome}
            />
            <Redirect to="/userHome" />
          </Switch>
        </PersistGate>
      </Router>
    </Provider>
  );
}

export default App;
