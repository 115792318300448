import Style from "./button.module.css";

const Button = (props) => {
  return (
    <div
      className={
        props.desktop ? Style.ticketNextButtonDesktop : Style.ticketNextButton
      }
      style={props.style}
    >
      <button
        type="submit"
        className={
          props.disabled
            ? `${Style.buttonNavbarDisabled} ${Style.buttonText}`
            : `${Style.buttonNavbar} ${Style.buttonText}`
        }
        style={props.buttonStyle}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    </div>
  );
};

export default Button;
