import Style from "./labelWithHR.module.css";

const LabelWithHR = (props) => {
  return (
    <div className={Style.labels}>
      {props.label}
      <hr className={Style.hr} />
    </div>
  );
};

export default LabelWithHR;
