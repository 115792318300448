import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Octagon from "../common/assets/octagon.svg";
import NotExistParkingZone from "../common/assets/notExistParkingZone.png";
import ModalHeaderWithImage from "../common/modal/modalHeadereWithImage";
import { getModalStyle } from "../reusable/functions";
import Button from "../common/button/button";
import { useTranslation } from "react-i18next";

const ModalParkingZoneNotExist = (props) => {
  const { t } = useTranslation();

  const reEnterParkingZone = () => {
    props.closeParkingZoneModalNotExist();
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      showCloseIcon={false}
      center
      styles={getModalStyle()}
    >
      <ModalHeaderWithImage
        textLabel={t("UserHome.errorNotExistParkingZone")}
        iconSrc={Octagon}
        xIconVisibleOnImage
        onClose={props.close}
        backgroundSrc={NotExistParkingZone}
      />
      <Button
        text={t("UserHome.Repeat Entry")}
        onClick={reEnterParkingZone}
        buttonStyle={{ marginBottom: "20px" }}
      />
    </Modal>
  );
};
export default ModalParkingZoneNotExist;
