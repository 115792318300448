import React, { useEffect } from "react";
import Style from "./legalVisitor.module.css";
import DownloadWhite from "../common/assets/downloadWhite.svg";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Header from "../common/header/header";
import HeadingWithWelcomeLabel from "../common/label/headingWithWelcomeLabel";
import { useDispatch, useSelector } from "react-redux";
import { generateConfirmationReport } from "../actions/legalVisitor";
import { useTranslation } from "react-i18next";
import MobileHeader from "../common/header/mobileHeader";

const LegalVisitor = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const reservation = location?.state?.reservation;
  const dispatch = useDispatch();
  const { visitor } = useSelector((state) => state.visitor);

  const downloadConfiramtion = () => {
    dispatch(
      generateConfirmationReport({
        ...visitor,
        selectedLanguage: localStorage.getItem("beppoSelectedLanguage"),
      })
    );
  };

  return (
    <div className={Style.mainContainer}>
      <MobileHeader />
      <HeadingWithWelcomeLabel
        label={
          reservation
            ? t("LegalVisitor.ReservationTitle")
            : t("LegalVisitor.RegistrationTitle")
        }
      />
      {!reservation && (
        <div className={Style.welcomeMessageDiv}>
          <p className={Style.welcomeMessage}>{t("LegalVisitor.FirstPart")} </p>
          <p className={Style.welcomeMessage}>{t("LegalVisitor.SecondPart")}</p>
        </div>
      )}
      <div style={{ width: "-webkit-fill-available", margin: "20px" }}>
        <p style={{ marginBottom: "3px" }}>
          {t("LegalVisitor.PZNumber")} {visitor.parkingZone}
        </p>
        <p style={{ marginBottom: "3px" }}>
          {t("LegalVisitor.License plate")} {visitor.plateNumber}
        </p>
        <p style={{ marginBottom: "3px" }}>
          {t("LegalVisitor.Arrival")}
          {moment(visitor.validVisitorFrom).format("DD.MM.YYYY HH:mm")}
        </p>
        <p style={{ marginBottom: "3px" }}>
          {t("LegalVisitor.ValidTo")}
          {moment(visitor.validVisitorTo).format("DD.MM.YYYY HH:mm")}
        </p>
      </div>
      <div className={Style.btnPrimary} onClick={() => downloadConfiramtion()}>
        <img
          src={DownloadWhite}
          alt="download"
          style={{ margin: "auto", marginRight: "5px" }}
        />
        <p className={Style.btnText}>
          {reservation
            ? t("UserHome.Reservation")
            : t("LegalVisitor.Registration")}
        </p>
      </div>
    </div>
  );
};

export default LegalVisitor;
