import axios from "axios";
import { SIGN_IN_VISITOR } from "./types";
import { INVALID_PIN } from "./businessExceptionCode";

export const reservation =
  (
    reservation,
    setOpenModalAlreadyReserved,
    setOpenModalForPausedParking,
    setWrongPinModalOpen,
    history
  ) =>
  async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/visitor/reservation`,
        reservation
      )
      .then((response) => {
        if (response?.data?.error?.startsWith(INVALID_PIN)) {
          setWrongPinModalOpen(true);
          return;
        }
        if (response.status === 400) {
          setOpenModalAlreadyReserved(true);
          return;
        }
        if (response.status === 409) {
          setOpenModalForPausedParking(true);
          return;
        }

        dispatch({
          type: SIGN_IN_VISITOR,
          payload: response.data,
        });

        history.push({
          pathname: "/legalVisitor",
          state: {
            reservation: true,
          },
        });
      });
  };
