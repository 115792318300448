import Style from "./modalHeader.module.css";

const ModalHeader = (props) => {
  return (
    <div className={Style.modalHeader}>
      {props.src && (
        <img src={props.src} alt="Group13" width={"7%"} className={Style.img} />
      )}
      <div className={Style.textContainer}>
        <div className={Style.hairlineHeading} />
        <div className={Style.label}>{props.textLabel}</div>
      </div>
    </div>
  );
};

export default ModalHeader;
