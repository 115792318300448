import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { modalCloseButtonStyle } from "../../reusable/styles";
import Style from "./errorModal.module.css";

const ErrorModal = (props) => {
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={{
        modal: {
          width: props.width ? props.width : "539px",
          borderRadius: "4px",
        },
        closeButton: modalCloseButtonStyle,
        closeIcon: { fill: "red", marginTop: 5 },
      }}
    >
      <div className={Style.headerLine} />
      <div className={Style.modalTitle}>{props.title}</div>

      <div className={Style.bodyWrapper}>
        <div className={Style.body}>
          <div className={Style.message}>{props.errorMessage}</div>
        </div>
      </div>
    </Modal>
  );
};
export default ErrorModal;
