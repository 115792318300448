import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Controller, useForm } from "react-hook-form";
import ModalHeader from "../common/modal/modalHeader";
import { useHistory } from "react-router-dom";
import { getModalStyleWithoutRedBackground } from "../reusable/functions";
import Style from "./modalTodaysCode.module.css";
import { useDispatch } from "react-redux";
import { checkDailyCodeInParkingZone } from "../actions/parkingZone";
import { useTranslation } from "react-i18next";

const ModalTodaysCode = (props) => {
  const { control } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dailyCode = {
    name: "Guest",
    plateNumber: props.licensePlate.replaceAll("·", ""),
    parkingZoneId: props?.parkingZone,
  };

  const checkDailyCode = (localTodaysCode) => {
    dispatch(
      checkDailyCodeInParkingZone(
        { ...dailyCode, dailyCode: localTodaysCode },
        props.openWrongDailyCode,
        history
      )
    );
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      center
      styles={getModalStyleWithoutRedBackground()}
    >
      <ModalHeader textLabel={t("UserHome.Enter Code")} />
      <div className={Style.form}>
        <Controller
          name="todaysCode"
          control={control}
          render={() => (
            <input
              type="number"
              className={Style.inputMask}
              name="todaysCode"
              onChange={(e) => {
                if (e.target.value.length > 4) {
                  return;
                }
                if (e.target.value.length === 4) {
                  checkDailyCode(e.target.value);
                }
              }}
              defaultValue={props?.defaultValue?.toUpperCase()}
            />
          )}
        />
      </div>
    </Modal>
  );
};
export default ModalTodaysCode;
