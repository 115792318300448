import axios from "axios";
import {
  CHECK_VISITOR_STATUS,
  SIGN_IN_VISITOR,
  VISITOR_ALREADY_SIGNED_IN,
  VISITOR_WAITING_FOR_FREEZE_TIME_TO_EXPIRE,
} from "./types";

export const checkVisitor =
  (
    licensePlate,
    parkingZone,
    setErrorBlacklistModal,
    setModalVisitorNotAllowed,
    setModalAllreadySignedIn
  ) =>
  async (dispatch) => {
    await axios
      .get(
        `${
          process.env.REACT_APP_SERVICE_URL
        }/api/visitor/checkLegalParkingVisitor/${licensePlate
          .replace("·", "")
          .toUpperCase()}/${parkingZone}`
      )
      .then((response) => {
        if (response.data.status === "VISITOR_NOT_ALLOWED") {
          setModalVisitorNotAllowed(true);
          return;
        }
        if (response.data.status === "CHECKED_IN") {
          setModalAllreadySignedIn(true);
          return;
        }
        if (response.data.status === "SIGN_IN_WITHOUT_DAILY_CODE") {
          dispatch({
            type: SIGN_IN_VISITOR,
            payload: response.data.visitor,
          });
        }
        if (response.status === 403) {
          setErrorBlacklistModal(true);
          return dispatch({
            type: CHECK_VISITOR_STATUS,
            payload: "VISITOR_BLACKLISTED",
          });
        }
        dispatch({
          type: CHECK_VISITOR_STATUS,
          payload: response.data,
        });
      });
  };

export const checkVisitorWithDailyCode =
  (dailyCode, setModalWrongDailyCode, history) => async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/checkDailyCodeVisitor`,
        dailyCode
      )
      .then((response) => {
        if (response.status >= 400) {
          setModalWrongDailyCode(true);
        } else {
          dispatch({
            type: SIGN_IN_VISITOR,
            payload: response.data,
          });
          history.push({
            pathname: "/legalVisitor",
          });
        }
      });
  };

export const checkVisitorLicensePlateInParkingZone =
  (licensePlate, parkingZoneNumber) => async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/visitor/checkVisitorLicensePlateInParkingZone`,
        {
          licensePlate: licensePlate,
          parkingZoneNumber: parkingZoneNumber,
        }
      )
      .then((response) => {
        if (response.status === 409) {
          dispatch({
            type: VISITOR_WAITING_FOR_FREEZE_TIME_TO_EXPIRE,
            payload: true,
          });
          return;
        }
        if (response.status > 200 && response.status !== 208) {
          dispatch({
            type: VISITOR_ALREADY_SIGNED_IN,
            payload: true,
          });
          return;
        }
        dispatch({
          type: VISITOR_ALREADY_SIGNED_IN,
          payload: false,
        });
      });
  };

export const signInVisitorWithoutDailyCode =
  (
    dailyCode,
    history,
    setOpenModalAlreadySignedIn,
    setOpenModalForPausedParking
  ) =>
  async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/signInGuestWithoutDailyCode`,
        dailyCode
      )
      .then((response) => {
        if (response.status === 409) {
          setOpenModalForPausedParking(true);
          return;
        }
        if (response.status === 208) {
          setOpenModalAlreadySignedIn(true);
          return;
        }
        dispatch({
          type: SIGN_IN_VISITOR,
          payload: response.data,
        });
        history.push({
          pathname: "/legalVisitor",
        });
      });
  };

export const checkIfLicensePlateHasReservationAlready =
  (
    parkingZoneNumber,
    licensePlate,
    setOpenModalNewReservation,
    setModalReservation
  ) =>
  async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/visitor/checkIfLicensePlateHasReservationAlready`,
        {
          licensePlate: licensePlate,
          parkingZoneNumber: parkingZoneNumber,
        }
      )
      .then((response) => {
        if (response.data === true) {
          setOpenModalNewReservation(true);
        } else {
          setModalReservation(true);
        }
      });
  };
