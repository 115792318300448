import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ModalHeader from "../common/modal/modalHeader";
import { getModalStyleWithoutRedBackground } from "../reusable/functions";
import { useDispatch } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "../common/button/button";
import ReservationModalWrapper from "./reservationModalWrapper";
import { reservation } from "../actions/visitor";
import ErrorModal from "../common/modal/errorModal";
import { useHistory } from "react-router";
import LabelWithHR from "../common/label/labelWithHR";
import { Controller, useForm } from "react-hook-form";
import Style from "./reservationModal.module.css";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const formStyles = makeStyles((theme) => ({
  datePicker: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white",
    },
    "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
      {
        border: "1px solid white",
      },
  },
}));

const ReservationModal = (props) => {
  const { t } = useTranslation();
  const classesForm = formStyles();
  const dispatch = useDispatch();
  const { control } = useForm();
  const history = useHistory();

  const [date, setDate] = useState(dayjs(new Date()));
  const [dailyCode, setDailyCode] = useState("");

  const [openModalAlreadyReserved, setOpenModalAlreadyReserved] =
    useState(false);

  const [wrongPinModalOpen, setWrongPinModalOpen] = useState(false);

  const [openModalForPausedParking, setOpenModalForPausedParking] =
    useState(false);

  const reserveParkingPlace = (cancelPreviousReservation) => {
    dispatch(
      reservation(
        {
          ...props.reservation,
          reservationTime: date.$d,
          dailyCode: dailyCode,
          cancelPreviousReservation: cancelPreviousReservation,
        },
        setOpenModalAlreadyReserved,
        setOpenModalForPausedParking,
        setWrongPinModalOpen,
        history
      )
    );
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      center
      styles={getModalStyleWithoutRedBackground()}
    >
      <ModalHeader textLabel={t("UserHome.Reservation")} />
      <div style={{ padding: "20px" }}>
        <LabelWithHR label={t("UserHome.Enter start time")} />
        <ReservationModalWrapper>
          <DateTimePicker
            value={date}
            toolbarTitle="Wählen"
            className={[classesForm.datePicker, Style.reservationField]}
            minDateTime={dayjs(new Date())}
            onChange={(value) => {
              setDate(value);
            }}
            ampm={false}
            renderInput={(props) => <TextField {...props} />}
          />
        </ReservationModalWrapper>
        <LabelWithHR label={t("UserHome.Enter Code")} />
        <Controller
          name="todaysCode"
          control={control}
          render={() => (
            <input
              type="number"
              className={Style.inputMask}
              name="todaysCode"
              onChange={(e) => {
                setDailyCode(e.target.value);
              }}
            />
          )}
        />
      </div>
      <Button
        text={t("UserHome.Reservation")}
        buttonStyle={{ marginBottom: "20px" }}
        onClick={() => reserveParkingPlace(props.reservation.cancelPrevious)}
      />
      {openModalAlreadyReserved && (
        <ErrorModal
          title={t("Global.error")}
          errorMessage={t("UserHome.Visitor already registred")}
          open={openModalAlreadyReserved}
          onClose={() => setOpenModalAlreadyReserved(false)}
          width="400px"
        />
      )}

      {openModalForPausedParking && (
        <ErrorModal
          title={t("Global.error")}
          errorMessage={t("UserHome.errorPausedParking")}
          open={openModalForPausedParking}
          onClose={() => setOpenModalForPausedParking(false)}
          width="400px"
        />
      )}
      {wrongPinModalOpen && (
        <ErrorModal
          title={t("Global.error")}
          errorMessage={t("UserHome.Wrong daily code")}
          open={wrongPinModalOpen}
          onClose={() => setWrongPinModalOpen(false)}
          width="400px"
        />
      )}
    </Modal>
  );
};
export default ReservationModal;
