import axios from "axios";
import { SIGN_IN_VISITOR } from "./types";

export const checkValidParkingZone =
  (parkingZone, openNotExistParkingZoneModal, setParkingZone, closeModal) =>
  async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_SERVICE_URL
        }/api/parking-zone/parkingZoneNumber/${parkingZone.replaceAll(" ", "")}`
      )
      .then((response) => {
        if (response.status === 404) {
          openNotExistParkingZoneModal();
        } else {
          setParkingZone(response.data);
          closeModal();
        }
      });
  };

export const checkDailyCodeInParkingZone =
  (dailyCode, openWrongDailyCode, history) => async (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/checkDailyCodeVisitor`,
        dailyCode
      )
      .then((response) => {
        if (response.status === 400) {
          openWrongDailyCode();
        } else {
          dispatch({
            type: SIGN_IN_VISITOR,
            payload: response.data,
          });
          history.push({
            pathname: "/legalVisitor",
          });
        }
      });
  };
