import { SIGN_IN_VISITOR } from "../actions/types";

const initialState = {
  visitor: {},
};

const visitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_VISITOR:
      return {
        ...state,
        visitor: action.payload,
      };
    default:
      return state;
  }
};

export default visitorReducer;
